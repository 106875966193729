/* Add this to your CSS */
.markdown-content {
    width: 100%;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    line-height: 1.6;
  }
  
  .markdown-content h1 {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 1rem;
    color: #1976d2;
  }
  
  .markdown-content h2 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-top: 1.5rem;
    margin-bottom: 0.75rem;
    color: #1976d2;
  }
  
  .markdown-content h3 {
    font-size: 1.25rem;
    font-weight: 500;
    margin-top: 1.25rem;
    margin-bottom: 0.5rem;
  }
  
  .markdown-content p {
    margin-bottom: 1rem;
  }
  
  .markdown-content ul, .markdown-content ol {
    margin-left: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .markdown-content li {
    margin-bottom: 0.5rem;
  }
  
  .markdown-content blockquote {
    border-left: 4px solid #e0e0e0;
    padding-left: 1rem;
    margin-left: 0;
    color: #616161;
    font-style: italic;
  }
  
  .markdown-content code {
    background-color: #f5f5f5;
    padding: 0.2rem 0.4rem;
    border-radius: 3px;
    font-family: 'Courier New', monospace;
  }
  
  .markdown-content pre {
    background-color: #f5f5f5;
    padding: 1rem;
    border-radius: 4px;
    overflow-x: auto;
  }
  
  .markdown-content table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 1rem;
  }
  
  .markdown-content th, .markdown-content td {
    border: 1px solid #e0e0e0;
    padding: 0.5rem;
    text-align: left;
  }
  
  .markdown-content th {
    background-color: #f5f5f5;
    font-weight: 500;
  }