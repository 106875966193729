@keyframes pulse {
    0% {
        transform: scale(0.5);
    }
    100% {
        transform: scale(1.2);
    }
}

@keyframes pulse-ring {
    0% {
        transform: scale(0.5);
        opacity: 1;
    }
    100% {
        transform: scale(2);
        opacity: 0;
    }
}

circle#dot {
    transform-origin: 50% 50%;
    animation: pulse .6s -.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) alternate infinite;
}

circle#dot-no-pulse {
    transform-origin: 50% 50%;
    /*animation: pulse .6s -.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) alternate infinite;*/
}

circle#ring {
    transform-origin: 50% 50%;
    animation: pulse-ring 2s cubic-bezier(0.215, 0.215, 0.215, 0.215) infinite;
}
