@import url('https://fonts.googleapis.com/css2?family=Figtree&display=swap');

/* APP GLOBAL CSS RULES ARE IN new-globals.css FROM THE OLD SCSS FILE */


body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Poppins', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* PAGE SPECIFIC STYLES */

/* API PAGE */
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-grid-item > .react-resizable-handle::after{
    border-color: #5271FF !important;
    transform: translate(5px, 5px);
}

*:focus-visible {
    border: 0 !important;
}

body, #root, .App {
    max-width: 100vw !important;
    min-height: 100vh !important;
    /*overflow-x: hidden;*/
}

/* Initially hide the sidebar text */
.sidebar-item-text {
    display: none;
    width: 100%
}
.sidebar.active .sidebar-item-text {
    display: inline;
    width: 100%
}
.sidebar.active {
    display: flex;
    width: 100%
}

.sidebar:hover{
    width: 100%;
}

/* Show the text when hovering over the sidebar or a list item */
.sidebar:hover .sidebar-item-text,
.ListItemButton:hover .sidebar-item-text {
    display: inline; /* Or block, depending on layout */
}
.sidebar-item {
    height: 50px !important;
    width: 56px !important;
}
.sidebar:hover .sidebar-item {
    height: 50px !important;
    width: 100% !important;
}
.syntax-code-block code {
    white-space: pre-wrap!important;
    word-break: break-word!important;;
}

.firebase-emulator-warning {
    display: none !important;
}
.border-shadow {
    box-shadow: rgba(0, 0, 0, 0.04) 0px 5px 22px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.border-shadow.error {
    box-shadow: rgba(241, 92, 92, 0.1) 0px 5px 22px 0px, rgba(241, 92, 92, 0.2) 0px 0px 0px 1px;
}
.question.error {
    background: rgba(241, 92, 92, 0.02);
}
.bg-gradient {
    background-image: linear-gradient(to right, #7F52FF, #5271FF, #5DAFFB);
}
/* GRADIENT BUTTON (E.G. LOGIN BUTTON) */
.gradient-btn {
    background-image: linear-gradient(to right, #7F52FF, #5271FF, #5DAFFB);
    width: 100%;
    border-radius: 8px!important;
    color: #fefefe !important;
    position: fixed;

}
/* GRADIENT BUTTON (E.G. LOGIN BUTTON) */
.gradient-btn.Mui-disabled {
    background: rgba(0,0,0,0.3) !important;
    width: 100%;
    border-radius: 8px!important;
}

.gradient-outlined-btn {
    background-color: transparent;
    border: 2px solid;
    border-image: linear-gradient(to right, #7F52FF, #5271FF, #5DAFFB) 1;
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    background-image: linear-gradient(to right, #7F52FF, #5271FF, #5DAFFB);
    border-radius: 8px !important;
    text-fill-color: transparent; /* For Safari */
}

.gradient-outlined-btn.disabled {
    border-color: rgba(0,0,0,0.3);
    color: rgba(0,0,0,0.3);
    background-image: none;
    background-color: transparent;
    background-clip: unset;
    -webkit-background-clip: unset;
}


.gradient-btn p {
    text-transform: capitalize !important;
    font-family: 'Poppins', serif;
    color: white;
    font-weight: 600;
    padding: 5px;
}

.gradient-btn:hover {
    background-image: linear-gradient(to right, #6f47de, #4764e7, #71bbff);
    transform: translateY(-4px);
    transition: all 0.3s ease-in-out;
    -webkit-transition: 0.3s;
    /*background: linear-gradient(141deg, rgba(67,9,121,1) 3%, rgba(0,168,255,1) 100%);*/
}

.google-btn {
    box-shadow: none!important;
    text-align: left!important;
    width: fit-content!important;
    margin: 0px 0px 0px -30px!important;
}

.logout-container {
    font-weight: 600;
    padding: 16px;
    margin-bottom: 22px;
    align-self: center;
    display: flex;
    align-items: center;
    width: -webkit-fill-available;
    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
}


.drawer-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    height: -webkit-fill-available;
}

.active-link {
    color: #5271FF !important; /* This will ensure the active link is red */
    /* Add other styles here if needed */
}

.project-item-container {
    border: 1px solid #EBEBEB; /* Assuming the initial borderColor was overridden */
    background-color: #F6F8FC;
    border-radius: 16px;
    padding: 14px 24px 18px 24px;
}

.project-item .title {
    font-size: 1.5em;
    font-weight: 600;
    color: #505050;
    font-family: 'Poppins', serif;
}

.project-item .date-under {
    color: #A8A8A8;
    font-family: 'Poppins', serif;
    font-size: 1em;
    margin-top: 3px;
}

.project-item .tooltip-box {
    display: flex;
    flex-direction: row;
    justify-content: left;
}

.tooltip-box .tooltips {
    color: #3A3D45;
    font-size: 1em;
    font-family: 'Poppins';
    font-weight: bold;

}

.tooltip-box .tooltips.light {
    color: #929292;
    margin-left: 4px;
    font-weight: 500;
}

.list-of-questions .number {
    color: #5271FF;
    font-size: 1.3em;
    font-weight: 600;
    margin-top: -1px;
}

.list-of-questions .question {
    color: #505050;
    font-size: 1.2em;
    font-weight: 500;
    margin-left: 10px;
}

.smaller-subtitles {
    font-size: 1em;
    font-weight: 600;
    color: #505050;
    margin-top: 10px;
    font-family: 'Poppins', serif;
}

.button-clean {
    padding: 4px 20px!important;
    font-size: 1em!important;
    font-weight: 500!important;
}

/* MUI OVERWRITES */
.Muibox-overwrite-css {
    box-shadow: 0px 0px 8px 1px rgb(0 0 0 / 6%)!important;
}

.no-shadow {
    box-shadow: none!important;
}

.MuiButton-root {
    text-transform: capitalize !important;
}

.MuiOutlinedInput-root {
    border-radius: 13px!important;
    padding: 0px 0px 0px 10px!important;
    margin: 4px 0px 10px 0px!important;
}

.MuiOutlinedInput-root input {
    padding: 11px 4px!important;
}

.MuiOutlinedInput-input {
    padding: 11px 4px!important;
}

.MuiButtonBase-root.MuiChip-root {
    margin-bottom: 4px!important;
}

.MuiChip-label {
    font-size: 1em!important;
    font-family: 'Plus Jakarta Sans', sans-serif!important;

}

.MuiPaper-root.MuiPaper-elevation.MuiCard-root {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
}

.add-question-to-survey-modal .MuiDialog-container {
    opacity: 1!important;
    display: flex!important;
    align-items: stretch!important;
    flex-direction: column!important;
    /*transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;*/
    margin: auto!important;
}

.grid-container-of-languages {
    display: flex!important;
    flex-direction: column!important;
    align-items: flex-start!important;
}

.add-question-to-survey-modal .MuiPaper-root {
    left: 50%;
    transform: translateX(-50%);
}
/* Mobile devices */
@media (max-width: 768px) {
    .add-question-to-survey-modal .MuiPaper-root {
        left: unset;
        transform: unset;
    }
}

.MuiAutocomplete-root {
    margin-top: 4px!important;
}

.MuiFilledInput-root input {
    padding: 10px;
    background-color: white;
    padding-left: 8px
}

.MuiPaper-root.MuiPopover-paper  {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06)!important;
    border-radius: 10px!important;
    border: 1px solid #ececec !important;
    border-style: solid;
}

.MuiFilledInput-root {
    background-color: transparent !important;
}

.MuiSelect-select {
    background-color: transparent !important;
}

.MuiFormControl-root {
    background-color: transparent !important;
}

/* LOGIN SCREENS */
.login-bg {
    position: relative; /* Set position to relative for the parent element */
    background-image: url("/src/assets/login/woman-phone.png") !important;
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: #1DAEFF;
}

.login-bg::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*background-image: url("/src/assets/login/type-with-your-voice 1.png");*/
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 400px;
    z-index: 1; /* Set a higher z-index for the pseudo-element */
}

#sidebar a.active {
    font-weight: 900;
    color: #234fd9;
}

.react-html5-camera-photo>img, .react-html5-camera-photo>video {
    width: 100% !important;
    height: auto !important;
}

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
