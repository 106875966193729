/* Font and Perfect Scrollbar imports */
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@500&family=Roboto:wght@300;400;500;700;900;&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&family=Roboto+Slab:wght@100..900&display=swap');


/* Root styles */
.root {
    height: 100%;
    position: absolute;
}

/* App container styles */
.App {
    position: absolute;
    height: 100%;
    width: 100%;
    /*overflow-x: hidden;*/
}

.App .content-wrap {
    width: 100%;
    min-height: 100%;
    max-width: 1200px !important;
    height: 100%;
    /* background-color: $accentColor1; This line is commented out, replace $accentColor1 with an actual color value if needed */
}

.App .app-content-wrap {
    width: 100%;
    min-height: 100%;
    height: 100%;
}

.App .app-content-wrap.footer-up {
    min-height: calc(100vh - 216px);
}

/* Scrollbar customization */
*::-webkit-scrollbar {
    width: 7px;
    height: 9px;
}
/* Scrollbar customization */
.overflow-hidden::-webkit-scrollbar {
    width: 0 !important;
}/* Scrollbar customization */
.overflow-hidden:hover::-webkit-scrollbar {
    width: 7px !important;
}

*::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(189, 189, 189, 0.3);
    background-color: rgba(153, 153, 153, 0.8);
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    *::-webkit-scrollbar {
        display: none;
    }

    * {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
}


/* Input number arrows hidden */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.centered {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.white-text {
    color: white!important;
}
